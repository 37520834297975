import { Node, mergeAttributes } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import KatexEditor from "../components/KatexEditor.vue";
var prosemirrorState = require('prosemirror-state');

export default Node.create({
  name: "katex",

  group: "block",

  content: "inline*",

  addAttributes() {
    return {
      latexStr: {
        default: "x",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "katex",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["katex", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(KatexEditor);
  },
  
  addCommands() {
    return {
      setKatex: options => ({ chain }) => {
        return chain()
          .insertContent({
            type: this.name,
            attrs: options,
          })
          .command(({ tr, dispatch }) => {
          var _a;
          if (dispatch) {
            const { parent, pos } = tr.selection.$from;
            const posAfter = pos + 1;
            const nodeAfter = tr.doc.nodeAt(posAfter);
            // end of document
            if (!nodeAfter) {
                const node = (_a = parent.type.contentMatch.defaultType) === null || _a === void 0 ? void 0 : _a.create();
                if (node) {
                  tr.insert(posAfter, node);
                  tr.setSelection(prosemirrorState.TextSelection.create(tr.doc, posAfter));
                }
            }
            tr.scrollIntoView();
          }
          return true;
        }).run();
      },
    };
  },
});
