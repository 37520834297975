<template>
  <node-view-wrapper>
    <div contenteditable="false" class="katex-wrapper" @click="setText()">
      
      <!-- <b-button
        @click="setText()"
        class="edit-button"
        size="sm"
        variant="primary"
      > -->
      <b-icon-box-arrow-up-right class="edit-button" variant="primary"></b-icon-box-arrow-up-right>
      <!-- <span class="text-pointer" contenteditable="false"></span> -->
        <!-- <span>Edit</span> -->
      <!-- </b-button> -->
      <div
        contenteditable="false"
        class="noselect"
        v-katex="node.attrs.latexStr"
      ></div>
      <node-view-content v-show="false" class="content" />
      <b-modal
        no-close-on-backdrop
        size="m"
        id="edit-latex"
        v-model="modalShow">

        <template #modal-header>
          <h5 style="margin-bottom:0;">LaTex Editor</h5>
          <a
            href="https://www.tutorialspoint.com/latex_equation_editor.htm"
            target="_blank"
          >
          helper ⤴</a>
        </template>
        <template #default="{}">
          <div v-katex="text" class="mt-3 mb-0">{{ text }}</div>
          <hr />
          <b-form-textarea
            id="textarea"
            v-model="text"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </template>

        <template #modal-footer>
          <b-button class="mr-auto" variant="danger" @click="handleDelete()">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
            Delete
          </b-button>
          <b-button variant="primary" @click="handleSave()">Simpan</b-button>
        </template>
      </b-modal>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from "@tiptap/vue-2";
import "katex/dist/katex.min.css";

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,

  data() {
    return {
      text: "",
      modalShow: false,
    };
  },

  methods: {
    handleSave() {
      this.modalShow = false;
      if(this.text != this.node.attrs.latexStr){
        this.updateAttributes({
          latexStr: this.text,
        });
      }
    },
    handleDelete() {
      this.modalShow = false;
      this.deleteNode();
    },
    setText() {
      this.text = this.node.attrs.latexStr;
      this.modalShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.katex-wrapper {
  position: relative;
  :hover {
    cursor: pointer;
  }
}

.edit-button {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.katex-wrapper:hover .edit-button {
  display: inline-block;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
</style>
