<template>
  <b-container>
    <h1 style="text-align: center">tiptap 2.0 beta</h1>
    <b-card v-if="editor">
      <b-row align-h="between">
        <b-col>
          <b-button-group size="sm">
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleBulletList().run()"
              :pressed="editor.isActive('bulletList')"
              :class="{ 'is-active': editor.isActive('bulletList') }"
            >
              bullet list
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleOrderedList().run()"
              :pressed="editor.isActive('orderedList')"
              :class="{ 'is-active': editor.isActive('orderedList') }"
            >
              ordered list
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleCodeBlock().run()"
              :pressed="editor.isActive('codeBlock')"
              :class="{ 'is-active': editor.isActive('codeBlock') }"
            >
              code block
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleBlockquote().run()"
              :pressed="editor.isActive('blockquote')"
              :class="{ 'is-active': editor.isActive('blockquote') }"
            >
              blockquote
            </b-button>
          </b-button-group>
        </b-col>
        <b-col style="text-align: right">
          <b-button-group size="sm">
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().setParagraph().run()"
              :pressed="editor.isActive('paragraph')"
              :class="{ 'is-active': editor.isActive('paragraph') }"
            >
              paragraph
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
              :pressed="editor.isActive('heading', { level: 1 })"
              :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            >
              h1
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
              :pressed="editor.isActive('heading', { level: 2 })"
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            >
              h2
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
              :pressed="editor.isActive('heading', { level: 3 })"
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            >
              h3
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
              :pressed="editor.isActive('heading', { level: 4 })"
              :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
            >
              h4
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
              :pressed="editor.isActive('heading', { level: 5 })"
              :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
            >
              h5
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
              :pressed="editor.isActive('heading', { level: 6 })"
              :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
            >
              h6
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row align-h="between">
        <b-col>
          <b-button-group size="sm">
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleBold().run()"
              :pressed="editor.isActive('bold')"
              :class="{ 'is-active': editor.isActive('bold') }"
            >
              bold
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleItalic().run()"
              :pressed="editor.isActive('italic')"
              :class="{ 'is-active': editor.isActive('italic') }"
            >
              italic
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleStrike().run()"
              :pressed="editor.isActive('strike')"
              :class="{ 'is-active': editor.isActive('strike') }"
            >
              strike
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().toggleCode().run()"
              :pressed="editor.isActive('code')"
              :class="{ 'is-active': editor.isActive('code') }"
            >
              code
            </b-button>
          </b-button-group>
        </b-col>
        <b-col style="text-align: right">
          <b-button-group size="sm">
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().setHorizontalRule().run()"
            >
              horizontal rule
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().setHardBreak().run()"
            >
              hard break
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().setKatex({ latexStr: 'a=b+c' }).run()"
            >
              math formula
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row align-h="between">
        <b-col>
          <b-button-group size="sm">
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().unsetAllMarks().run()"
            >
              clear marks
            </b-button>
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().clearNodes().run()"
            >
              clear nodes
            </b-button>
          </b-button-group>
        </b-col>
        <b-col style="text-align: right">
          <b-button-group size="sm">
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().undo().run()"
              >undo</b-button
            >
            <b-button
              variant="outline-secondary"
              class="editor-button"
              @click="editor.chain().focus().redo().run()"
              >redo</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <h3>Editor</h3>
          <editor-content class="editor" :editor="editor" />
        </b-col>
        <b-col>
          <h3>Viewer</h3>
          <div class="editor">
            <span v-html="html" class="ProseMirror"></span>
          </div>
        </b-col>
      </b-row>
      <hr />
      <div class="export">
        <h3>JSON</h3>
        <pre><code v-html="json" /></pre>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import katex from "katex";
import KatexExtension from "../extensions/katex";

export default {
  components: {
    EditorContent,
  },

  data() {
    return {
      editor: null,
      html: null,
      json: null,
    };
  },

  mounted() {
    this.editor = new Editor({
      extensions: [StarterKit, KatexExtension],
      content: `
        <h2>
          Hewwwo?
        </h2>
        <ul>
          <li>
            (;•́︿•̀  ;) h….hewwo…?
          </li>
          <li>
            ……. ( •́  ‸•̀｀) hewwo….?
          </li>
          <li>
            is anybodwy hewre…?
          </li>
        </ul>
        <p>
          <strong>*BANG*</strong> ｀Σ ( •́△•̀|||)｀ HEWWO?!? HEWWO!!?!!?????
        </p>
        <pre><code class="language-css">OwO *notices you*
> nuzzels "Hewwo World"</code></pre>
        <blockquote>
          Sugoi! 👏 UwU
          <br />
          — Mom
        </blockquote>

        <katex latexstr='\\int_{-\\infty}^\\infty \\hat Uw\\hat U:=\\displaystyle \\frac{ρu,v(w)  [uwu-1 , vwv-1 ]}{[u -1wu, w-1 ]}.'></katex>
        <p>Apa kesimpulan yang didapat?</p>
      `,
    });
    // … and get the content after every change.
    this.json = this.editor.getJSON();
    this.html = this.editor.getHTML();
    this.editor.on("create", () => {
      this.$nextTick(() => {
        this.$el.querySelectorAll("katex").forEach((element) => {
          console.log(element.getAttribute("latexstr"));
          katex.render(element.getAttribute("latexstr"), element, {
            throwOnError: false,
          });
          element.parentNode.insertBefore(document.createElement("br"), element.nextSibling);
        });
      });
    });
    this.editor.on("update", () => {
      this.json = this.editor.getJSON();
      this.html = this.editor.getHTML();
      this.$nextTick(() => {
        this.$el.querySelectorAll("katex").forEach((element) => {
          console.log(element.getAttribute("latexstr"));
          katex.render(element.getAttribute("latexstr"), element, {
            throwOnError: false,
          });
          element.parentNode.insertBefore(document.createElement("br"), element.nextSibling);
        });
      });
    });
  },
  methods: {},

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */

.katex {
  margin-left: 1em;
}

.editor-button {
  margin: 0.1em 0;
}

.editor {
  :focus {
    outline-width: 0;
  }
  // background: #f6f6f6;
  border: 3px solid #0d0d0d;
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
}

.ProseMirror {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
